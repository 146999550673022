<template>
  <div :class="$style.ExchangeCourse">
    <div :class="$style.progress">
      <client-only>
        <UiRadialProgress
          :completed-steps="step"
          :diameter="24"
          :total-steps="totalSteps"
          start-color="#B5D841"
          stop-color="#B5D841"
          :stroke-width="2"
          :inner-stroke-width="2"
          inner-stroke-color="transparent"
        >
          <span>{{ step }}</span>
        </UiRadialProgress>
      </client-only>
    </div>
    <div v-if="!noTitle" :class="$style.title">{{ $t('current course') }}</div>
    <client-only>
      <div :class="$style.course">{{ courseString }}</div>
    </client-only>
  </div>
</template>

<script setup lang="ts">
import useCourse from '~/composables/calculator/useCourse';

const { step, totalSteps, courseString } = useCourse();

defineProps({
  noTitle: {
    type: Boolean,
    default: false,
  },
});
</script>

<style lang="scss" module>
.ExchangeCourse {
  display: flex;
  align-items: center;
  gap: 1rem;

  @include respond-to(xs) {
    flex-wrap: wrap;
    gap: 5px;
  }
}

.progress {
  height: 24px;
  width: 24px;

  span {
    font-size: 12px;
    line-height: 14px;
  }
}

.title {
  @include respond-to(xs) {
    font-size: 14px;
  }
}

.course {
  text-transform: uppercase;

  @include respond-to(xs) {
    font-size: 14px;
  }
}
</style>
